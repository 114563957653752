<template>
    <li v-if="urlList" class="custom-drop-down" :class="`li-menu-${props.index + 1}`">
        <router-link :to="props.url" v-if="isHumburger">
            <span class="index">{{ props.index + 1 > 9 ? props.index : "0" + (props.index + 1) }}.</span>
            <span @click="showGroup($event)">{{ props.name }}</span>
        </router-link>
        <router-link v-else :to="props.url">
            <span @click="navToogle($event)">{{ props.name }}</span>
        </router-link>
        <ul class="sub-list" ref="group">
            <li v-if="props.isHumburger" class="back-item">
                <router-link v-if="props.isHumburger" :to="props.url">
                    <i class="fas fa-long-arrow-alt-left"></i>
                    <span @click="hideGroup">{{ props.name }}</span>
                </router-link>
                <router-link v-else :to="props.url">
                    <span @click="navToogle($event)">{{ props.name }}</span>
                </router-link>
            </li>
            <li v-for="(group, index) in props.urlList" :key="index" :class="`li-menu-${index + 1} ${group.type}`">
                <router-link v-if="group.urlList" :to="group.url">
                    <span v-if="isHumburger" class="index">{{ index + 1 > 9 ? index : "0" + (index + 1) }}.</span>
                    <span v-if="isHumburger" @click="showSubGroup($event)">{{ group.name }}</span>
                    <span v-else>{{ group.name }}</span>
                </router-link>
                <router-link v-else :to="group.url">
                    <span v-if="isHumburger" class="index">{{ index + 1 > 9 ? index : "0" + (index + 1) }}.</span>
                    <span v-if="isHumburger" @click="navToogle($event)">{{ group.name }}</span>
                    <span v-else>{{ group.name }}</span>
                </router-link>

                <ul ref="sgroup" v-if="group.urlList" class="sub-list">
                    <li v-if="isHumburger" class="back-item">
                        <button @click="hideSubGroup">
                            <i class="fas fa-long-arrow-alt-left"></i>
                            <span>{{ group.name }}</span>
                        </button>
                    </li>

                    <li v-for="(subGroup, index) in group.urlList" :key="index" :class="`li-menu-${index + 1}`">
                        <router-link :to="subGroup.url">
                            <span v-if="isHumburger" class="index">{{ index + 1 > 9 ? index : "0" + (index + 1) }}.</span>
                            <span @click="navToogle($event)">{{ subGroup.name }}</span>
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </li>
    <li v-else :class="`li-menu-${props.index + 1}`">
        <router-link :to="props.url">
            <span v-if="props.isHumburger" class="index">{{ props.index + 1 > 9 ? props.index : "0" + (props.index + 1)
            }}.</span>
            <span @click="navToogle($event)">{{ props.name }}</span>
        </router-link>
    </li>
</template>

<script setup>
import { ref, defineProps,onUnmounted } from "vue";
import { navButton } from "@/assets/global/global"

const props = defineProps({ index: Number, name: String, url: String, type: String, urlList: Array, isHumburger: Boolean });
const showGroup = ((event) => {
    const elements = document.querySelectorAll(".nav__list");
    const findSubList = event.target.parentElement.parentElement.querySelector(".sub-list");
    elements.forEach((element) => { element.classList.add("links-list-hide"); });

    if (findSubList === null) {
        const elements = document.querySelectorAll(".nav__list");
        elements.forEach((element) => { element.classList.remove("links-list-hide"); });
    } else if (findSubList.classList.contains("show-group")) {
        findSubList.classList.remove("show-group");
    } else {
        findSubList.classList.add("show-group");
    }
})
const group = ref(null)
const showSubGroup = ((event) => {
    group.value.classList.add("links-list-hide");
    event.target.parentElement.parentElement.querySelector(".sub-list").classList.add("show-group");
})
const hideGroup = (() => {
    const subList = document.querySelectorAll(".sub-list");
    const linksList = document.querySelector(".nav__list");
    const elements = document.querySelectorAll(".nav__list");
    subList.forEach((sub) => { sub.classList.remove("show-group") })
    linksList.classList.remove("links-list-hide");
    elements.forEach((element) => { element.classList.remove("links-list-hide"); });
})
const hideSubGroup=((event) => {
    event.target.parentElement.parentElement.parentElement.parentElement.parentElement.classList.remove("links-list-hide")
    event.target.parentElement.parentElement.parentElement.classList.remove("show-group")
})
const navToogle = ((event) => {
    navButton()
    const checkMenu = document.querySelector("body");
    if (checkMenu.classList.contains("hamburger-menu")) {
        hideGroup()
        hideSubGroup(event)
    }
})

onUnmounted(() => {
    showGroup.kill;
    showSubGroup.kill;
    hideGroup.kill;
    hideSubGroup.kill;
    showGroup.kill;
    navToogle.kill;
})
</script>

<style lang="scss">
.index {
    font-size: 14px;
    opacity: .3;
    pointer-events: none;
    margin-right: 5px;
}
</style>