import { store } from "@/store/index";
import Scrollbar from "smooth-scrollbar";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const numberText = (e) => (e < 10 && e > 0 ? `0${e}` : e);

export function navButton() {
	document.body.classList.toggle("nav-active");
	navBarHideSubMenu();
}

export function navBarHideSubMenu() {
	const customDropDown = document.querySelectorAll(
		".header-top .custom-drop-down"
	);
	customDropDown.forEach((elUl) => {
		const ulSubMenu = elUl.querySelectorAll(".sub-list");
		ulSubMenu.forEach((x) => (x.style.display = "none"));
	});
}

export function hamburgerNavBar() {
	const siteHeader = document.querySelector(".site-header");
	const uList = siteHeader.querySelector(".accent-menu .extend-container");
	if (uList.length <= 0) return;
	uList.classList.add("nav__list");
	const liCustomDropDown = uList.querySelectorAll("li.custom-drop-down");
	liCustomDropDown.forEach((el) => el.classList.add("nav__list-dropdown"));
	const customDrop = uList.querySelectorAll(".extend-container li");
	customDrop.forEach((li) => li.classList.add("nav__list-item"));
	const hedaerMobile = document.querySelector(".header-top .nav .nav__content");
	if (hedaerMobile !== undefined) hedaerMobile.prepend(uList);
}

export function hamburgerMenu(value) {
	if (!value) return;

	document.body.classList.add("hamburger-menu");
	return hamburgerNavBar();
}

export function getWord(string, count = 1, start = 0) {
	if (!string.length) return;

	return string
		.split(" ")
		?.slice(start, start + count)
		?.join(" ");
}

export function getSingleData(value, inData, whereName = "id") {
	if (typeof inData === "object")
		return inData?.find((item) => {
			if (item[whereName] === value) return item;
			return null;
		});

	return {};
}

export function setNextProject(Current_ID, dataLength = 0) {
	if (dataLength === 0) return;
	return Current_ID === dataLength ? 1 : Current_ID + 1;
}

export function setSlug(slugString) {
	return slugString
		.toString()
		.toLowerCase()
		.replace(/\s+/g, "-")
		.replace(/&/g, "-and-")
		.replace(/--/g, "-");
}

let showAnim = null;
export function hideNav() {
	const checkMenu = document.querySelector("body");
	let menuState = null;

	if (checkMenu.classList.contains("hamburger-menu")) {
		menuState = ".header-container";
	} else {
		menuState = ".site-header";
	}

	showAnim = gsap
		.from(menuState, { yPercent: -100, paused: true, duration: 0.1 })
		.progress(1);

	ScrollTrigger.create({
		onUpdate: (self) => {
			if (checkMenu.classList.contains("nav-active")) {
				showAnim?.kill;
			} else if (self.direction === -1) {
				showAnim.play();
			} else {
				showAnim.reverse();
			}
		},
		onEnter() {
			document.querySelector(menuState).style = "background-color: #000";
		},
		onToggle() {
			showAnim.play();
			document.querySelector(menuState).style = "background-color: transparent";
		},
		mounted() {
			window.onbeforeunload = () => {
				return showAnim.kill();
			};
		},
	});
	setTimeout(ScrollTrigger.refresh, 500);
	return showAnim;
}

export function cursorGrowing() {
	const moveCursor = document.querySelector(".cursor");
	const cursorScale = document.querySelectorAll(".scale");

	cursorScale.forEach((scale) => {
		scale.addEventListener("mousemove", () => {
			if (!moveCursor) { return }
			moveCursor.classList.add("grow");
			if (scale.classList.contains("small")) {
				moveCursor.classList.remove("grow");
				moveCursor.classList.add("grow-small");
			}
		});

		scale.addEventListener("mouseleave", () => {
			if (!moveCursor) { return }
			moveCursor.classList.remove("grow");
			moveCursor.classList.remove("grow-small");
		});
	});
}

export function smoothScrollBar() {
	const scroller = document.querySelector(".dsn-scrollbar");

	if (!store.smoothScroll) {
		return hideNav();
	}
	scroller.setAttribute("style", "overflow-y:visible;height:100vh !important")
	const bodyScrollBar = Scrollbar.init(scroller, {
		damping: 0.05,
		renderByPixels: false,
		continuousScrolling: true,
		delegateTo: document,
		alwaysShowTracks: false,
		thumbMinSize: 5,
		stagger: false,
		invalidateOnRefresh: true,
	});

	ScrollTrigger.scrollerProxy(".dsn-scrollbar", {
		scrollTop(value) {
			if (arguments.length) {
				bodyScrollBar.scrollTop = value;
			}
			return bodyScrollBar.scrollTop;
		},
	});

	bodyScrollBar.addListener(ScrollTrigger.update);
	ScrollTrigger.defaults({ scroller });
	hideNav();
}


window.addEventListener("load", hamburgerMenu());
window.addEventListener(
	"beforeunload",
	showAnim !== null ? ScrollTrigger.kill() : showAnim
);
