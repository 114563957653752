import { createStore } from "vuex";
import { reactive } from "vue";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});

export const store = reactive({
  smoothScroll: true,
  mouseGrow: true,
  themeMode: "",
});
