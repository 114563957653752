<template>
    <div ref="moveCursor" class="cursor"></div>
</template>

<script setup>
import { gsap } from "gsap";
import { onMounted, onUnmounted, ref } from "vue";
import { store } from '../../store/index'
import { cursorGrowing } from "@/assets/global/global"


const moveCursor = ref(null);

let animate = null;

onMounted(() => {
    cursorGrowing()
    if (!store.mouseGrow) {
        moveCursor.value.setAttribute("style", "display:none")
        return
    }
    
    let mouseX = 0;
    let mouseY = 0;

    animate = gsap.to({}, 0.02, {
        repeat: -1,
        onRepeat() {
            gsap.set(moveCursor.value, { css: { left: mouseX, top: mouseY, }, });
        },
    });

    window.addEventListener("mousemove", (e) => {
        mouseX = e.clientX;
        mouseY = e.clientY;
    });
});

onUnmounted(() => {
    if (animate.scrollTrigger)
        animate.scrollTrigger.kill();

    animate.kill();
});
</script>

<style lang="scss">
.cursor {
    position: fixed;
    width: 35px;
    height: 35px;
    z-index: 999999;
    pointer-events: none;
    background-color: #fff;
    border-radius: 50%;
    transform-origin: 0% 0%;
    transition: all 0.055s ease-in-out, transform 0.5s ease-in-out;
    mix-blend-mode: exclusion;
    margin-left: -15px;
    margin-top: -15px;
    transform-origin: center center;
}

.grow,
.grow-small {
    transform: scale(3);
}

.grow-small {
    transform: scale(1);
}

.scale {
    cursor: none;
}
</style>
